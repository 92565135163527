import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class WhyChooseUsV2 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return  <section className="cta-three  go-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 d-flex">
                  <div className="my-auto">
                    <div className="cta-three__image">
                      <img src={publicUrl+"assets/images/resources/571.png"} alt={ imagealt } />
                    </div>{/* /.cta-three__image */}
                  </div>{/* /.my-auto */}
                </div>{/* /.col-lg-7 */}
                <div className="col-lg-5">
                  <div className="cta-three__content  go-top">
                    <div className="block-title-two text-left">
                      <p>Keunggulan</p>
                      <h3>Apa yang Bisa <br /> Kami Buat?</h3>
                    </div>{/* /.block-title-two */}
                    <div className="cta-three__box-wrap">
                      <div className="cta-three__box">
                        <div className="cta-three__box-icon">
                          <i className="far fa-desktop" />
                        </div>{/* /.cta-three__icon */}
                        <div className="cta-three__box-content">
                          <h3>Aplikasi Manajemen Sekolah</h3>
                          <p>Kami buat aplikasi manajemen sekolah sesuai dengan kebutuhan anda </p>
                        </div>{/* /.cta-three__box-content */}
                      </div>{/* /.cta-three__box */}
                      <div className="cta-three__box">
                        <div className="cta-three__box-icon">
                          <i className="far fa-desktop" />
                        </div>{/* /.cta-three__icon */}
                        <div className="cta-three__box-content">
                          <h3>Aplikasi HRD</h3>
                          <p>Maksimalkan pengelolaan HR yang disesuaikan dengan kebutuhan organisasi anda </p>
                        </div>{/* /.cta-three__box-content */}
                      </div>{/* /.cta-three__box */}
                      <div className="cta-three__box">
                        <div className="cta-three__box-icon">
                          <i className="fa fa-desktop" />
                        </div>{/* /.cta-three__icon */}
                        <div className="cta-three__box-content">
                          <h3>Absensi</h3>
                          <p>Digitalisasi absensi institusi anda yang dikostumisasi sesuai institusi anda </p>
                        </div>{/* /.cta-three__box-content */}
                      </div>{/* /.cta-three__box */}
                    </div>{/* /.cta-three__box-wrap */}
                    <a href="https://api.whatsapp.com/send?phone=6283834020351&text=Aku%20tertarik%20untuk%20membuat%20aplikasi%20sesuai%20kebutuhanku!" className="thm-btn cta-three__btn">Layanan Kami Lainnya <i className="fa fa-angle-double-right" /></a>
                    {/* /.thm-btn cta-three__btn */}
                  </div>{/* /.cta-three__content */}
                </div>{/* /.col-lg-5 */}
              </div>{/* /.row */}
            </div>{/* /.container */}
          </section>


        }
}

export default WhyChooseUsV2