import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Pricing extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return  <section className="pricing-one" style={{backgroundImage: 'url('+publicUrl+'assets/images/shapes/dotted-patter-2.png)'}}>
        <div className="container">
          <div className="pricing-one__block">
            <div className="block-title-two text-left">
              <p>get a quote</p>
              <h3>Harga yang <br /> Sangat Terjangkau</h3>
            </div>{/* /.block-title-two */}
            <div className="pricing-one__btn-block">
              <ul className="list-unstyled nav nav-tabs">

              </ul>{/* /.list-unstyled nav nav-tabs */}
            </div>{/* /.pricing-one__btn-block */}
          </div>{/* /.pricing-one__block */}
          <div className="tab-content">
            <div className="tab-pane show active  animated fadeInUp" id="monthly">
              <div className="row">
                <div className="col-lg-4">
                  <div className="pricing-one__single">
                    <div className="pricing-one__inner">
                      <img src={publicUrl+"assets/images/shapes/pricing-i-1-1.png"} alt={ imagealt } />
                      <div className="pricing-one__top">
                        <h3>Santai</h3>
                        <p><span>Rp</span>999k,-</p>
                      </div>{/* /.pricing-one__top */}
                      <ul className="pricing-one__list list-unstyled">
                        <li><i className="far fa-check" />5 kali revisi</li>
                        <li><i className="far fa-check" />2 Bulan jadi</li>
                        <li><i className="far fa-check" />5 Kali konsultasi</li>
                        <li><i className="far fa-check" />24 Jam bantuan</li>
                      </ul>{/* /.pricing-one__list list-unstyled */}
                      <a href="https://api.whatsapp.com/send?phone=6283834020351&text=Aku%20tertarik%20untuk%20membuat%20aplikasi%20sesuai%20kebutuhanku!" className="thm-btn pricing-one__btn">Choose Plan <i className="fa fa-angle-double-right" /></a>{/* /.thm-btn pricing-one__btn */}
                    </div>{/* /.pricing-one__inner */}
                  </div>{/* /.pricing-one__single */}
                </div>{/* /.col-lg-4 */}
                <div className="col-lg-4">
                  <div className="pricing-one__single">
                    <div className="pricing-one__inner">
                      <span className="pricing-one__corner-ribbon">Best</span>
                      {/* /.pricing-one__corner-ribbon */}
                      <img src={publicUrl+"assets/images/shapes/pricing-i-1-2.png"} alt={ imagealt } />
                      <div className="pricing-one__top">
                        <h3>Cepat</h3>
                        <p><span>Rp</span>1.499k</p>
                      </div>{/* /.pricing-one__top */}
                      <ul className="pricing-one__list list-unstyled">
                        <li><i className="far fa-check" />10 Kali revisi</li>
                        <li><i className="far fa-check" />1 Bulan jadi</li>
                        <li><i className="far fa-check" />10 Kali konsultasi</li>
                        <li><i className="far fa-check" />24 Jam bantuan</li>
                      </ul>{/* /.pricing-one__list list-unstyled */}
                      <a href="https://api.whatsapp.com/send?phone=6283834020351&text=Aku%20tertarik%20untuk%20membuat%20aplikasi%20sesuai%20kebutuhanku!" className="thm-btn pricing-one__btn">Choose Plan <i className="fa fa-angle-double-right" /></a>{/* /.thm-btn pricing-one__btn */}
                    </div>{/* /.pricing-one__inner */}
                  </div>{/* /.pricing-one__single */}
                </div>{/* /.col-lg-4 */}
                <div className="col-lg-4">
                  <div className="pricing-one__single">
                    <div className="pricing-one__inner">
                      <img src={publicUrl+"assets/images/shapes/pricing-i-1-3.png"} alt={ imagealt } />
                      <div className="pricing-one__top">
                        <h3>Flash</h3>
                        <p><span>Rp</span>2.999k</p>
                      </div>{/* /.pricing-one__top */}
                      <ul className="pricing-one__list list-unstyled">
                        <li><i className="far fa-check" />20 Kali revisi</li>
                        <li><i className="far fa-check" />20 Hari jadi</li>
                        <li><i className="far fa-check" />20 Kali konsultasi</li>
                        <li><i className="far fa-check" />24 Jam bantuan</li>
                      </ul>{/* /.pricing-one__list list-unstyled */}
                      <a href="https://api.whatsapp.com/send?phone=6283834020351&text=Aku%20tertarik%20untuk%20membuat%20aplikasi%20sesuai%20kebutuhanku!" className="thm-btn pricing-one__btn">Choose Plan <i className="fa fa-angle-double-right" /></a>{/* /.thm-btn pricing-one__btn */}
                    </div>{/* /.pricing-one__inner */}
                  </div>{/* /.pricing-one__single */}
                </div>{/* /.col-lg-4 */}
              </div>{/* /.row */}
            </div>{/* /.tab-pane fade in active */}
            <div className="tab-pane animated fadeInUp " id="yearly">
              <div className="row">
                <div className="col-lg-4">
                  <div className="pricing-one__single">
                    <div className="pricing-one__inner">
                      <img src={publicUrl+"assets/images/shapes/pricing-i-1-1.png"} alt={ imagealt } />
                      <div className="pricing-one__top">
                        <h3>Startup</h3>
                        <p><span>$</span>69.99</p>
                      </div>{/* /.pricing-one__top */}
                      <ul className="pricing-one__list list-unstyled">
                        <li><i className="far fa-check" />50GB Bandwidth</li>
                        <li><i className="far fa-check" />Business &amp; Financ Analysing</li>
                        <li><i className="far fa-check" />24 hour support</li>
                        <li><i className="far fa-check" />Customer Managemet</li>
                      </ul>{/* /.pricing-one__list list-unstyled */}
                      <a href="#" className="thm-btn pricing-one__btn">Choose Plan <i className="fa fa-angle-double-right" /></a>{/* /.thm-btn pricing-one__btn */}
                    </div>{/* /.pricing-one__inner */}
                  </div>{/* /.pricing-one__single */}
                </div>{/* /.col-lg-4 */}
                <div className="col-lg-4">
                  <div className="pricing-one__single">
                    <div className="pricing-one__inner">
                      <span className="pricing-one__corner-ribbon">Best</span>
                      {/* /.pricing-one__corner-ribbon */}
                      <img src={publicUrl+"assets/images/shapes/pricing-i-1-2.png"} alt={ imagealt } />
                      <div className="pricing-one__top">
                        <h3>Regular</h3>
                        <p><span>$</span>79.99</p>
                      </div>{/* /.pricing-one__top */}
                      <ul className="pricing-one__list list-unstyled">
                        <li><i className="far fa-check" />50GB Bandwidth</li>
                        <li><i className="far fa-check" />Business &amp; Financ Analysing</li>
                        <li><i className="far fa-check" />24 hour support</li>
                        <li><i className="far fa-check" />Customer Managemet</li>
                      </ul>{/* /.pricing-one__list list-unstyled */}
                      <a href="#" className="thm-btn pricing-one__btn">Choose Plan <i className="fa fa-angle-double-right" /></a>{/* /.thm-btn pricing-one__btn */}
                    </div>{/* /.pricing-one__inner */}
                  </div>{/* /.pricing-one__single */}
                </div>{/* /.col-lg-4 */}
                <div className="col-lg-4">
                  <div className="pricing-one__single">
                    <div className="pricing-one__inner">
                      <img src={publicUrl+"assets/images/shapes/pricing-i-1-3.png"} alt={ imagealt } />
                      <div className="pricing-one__top">
                        <h3>Premium</h3>
                        <p><span>$</span>99.99</p>
                      </div>{/* /.pricing-one__top */}
                      <ul className="pricing-one__list list-unstyled">
                        <li><i className="far fa-check" />50GB Bandwidth</li>
                        <li><i className="far fa-check" />Business &amp; Financ Analysing</li>
                        <li><i className="far fa-check" />24 hour support</li>
                        <li><i className="far fa-check" />Customer Managemet</li>
                      </ul>{/* /.pricing-one__list list-unstyled */}
                      <a href="#" className="thm-btn pricing-one__btn">Choose Plan <i className="fa fa-angle-double-right" /></a>{/* /.thm-btn pricing-one__btn */}
                    </div>{/* /.pricing-one__inner */}
                  </div>{/* /.pricing-one__single */}
                </div>{/* /.col-lg-4 */}
              </div>{/* /.row */}
            </div>{/* /.tab-pane fade in active */}
          </div>{/* /.tab-content */}
        </div>{/* /.container */}
      </section>

        }
}

export default Pricing