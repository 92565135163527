import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class CtaV2 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <section className="cta-two  go-top" style={{backgroundImage: 'url('+publicUrl+'assets/images/backgrounds/banneer.png)'}}>
		  <div className="container text-center">
		    <h3>Konsultan Kami<br />
		      Akan Membantumu</h3>
		    <a href="https://api.whatsapp.com/send?phone=6283834020351&text=Aku%20tertarik%20untuk%20membuat%20aplikasi%20sesuai%20kebutuhanku!" className="thm-btn">Read More <i className="fa fa-angle-double-right" /></a>{/* /.thm-btn */}
		  </div>{/* /.container */}
		</section>

        }
}

export default CtaV2