// FloatingWhatsApp.js
import React from "react";

const FloatingWhatsApp = () => {
  const openWhatsApp = () => {
    const phoneNumber = "6283834020351"; // Ganti dengan nomor WhatsApp kamu
    const message = "Aku tertarik untuk membuat aplikasi sesuai kebutuhanku!"; // Ganti dengan pesan default yang diinginkan
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    window.open(whatsappURL, "_blank");
  };

  return (
    <div
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        backgroundColor: "#25D366",
        borderRadius: "50%",
        width: "60px",
        height: "60px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
        cursor: "pointer",
      }}
      onClick={openWhatsApp}
    >
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
        alt="WhatsApp"
        style={{ width: "35px", height: "35px" }}
      />
    </div>
  );
};

export default FloatingWhatsApp;
