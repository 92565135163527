import React, { Component } from 'react';
import FloatingWhatsApp from "../FloatingWhatsApp";
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class BannerV2 extends Component {
	 componentDidMount() {
	 	let publicUrl = process.env.PUBLIC_URL+'/'
        const cssUrl =   publicUrl + "assets/css/color-2.css";
        this.addStyle(cssUrl);

        const $ = window.$;
        if ($('.thm__owl-carousel').length) {
            $('.thm__owl-carousel').each(function () {
                var Self = $(this);
                var carouselOptions = Self.data('options');
                var carouselPrevSelector = Self.data('carousel-prev-btn');
                var carouselNextSelector = Self.data('carousel-next-btn');
                var thmCarousel = Self.owlCarousel(carouselOptions);
                if (carouselPrevSelector !== undefined) {
                    $(carouselPrevSelector).on('click', function () {
                        thmCarousel.trigger('prev.owl.carousel');
                        return false;
                    });
                }
                if (carouselNextSelector !== undefined) {
                    $(carouselNextSelector).on('click', function () {
                        thmCarousel.trigger('next.owl.carousel');
                        return false;
                    });
                }
            });
        }
    }

    addStyle = url => {
        const style = document.createElement("link");
        style.href = url;
        style.rel = "stylesheet";
        style.async = true;

        document.head.appendChild(style);


    }
    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <section className="banner-two  go-top">
			  <img src={publicUrl+"assets/images/shapes/banner-shape-1-1.png"} alt="Banner" className="banner-two__moc" />
			  <div className="banner-two__image-carousel " data-options="{&quot;loop&quot;: true, &quot;items&quot;: 1, &quot;margin&quot;: 0, &quot;dots&quot;: false, &quot;nav&quot;: false, &quot;animateOut&quot;: &quot;slideOutDown&quot;, &quot;animateIn&quot;: &quot;fadeIn&quot;, &quot;active&quot;: true, &quot;smartSpeed&quot;: 1000, &quot;autoplay&quot;: true, &quot;autoplayTimeout&quot;: 7000, &quot;autoplayHoverPause&quot;: false}">
			    <div className="item">
			      <img src={publicUrl+"assets/images/resources/prog.png"} alt="Banner" />
			    </div>{/* /.item */}

			  </div>{/* /.banner-two__image-carousel */}
			  <div className="container">
			    <div className="row">
			      <div className="col-xl-7">
			        <div className="banner-two__content">
			          <h3><span>Shiteru AI </span> <br />Kami Buat <br />  <em>Aplikasimu!</em></h3>
			          <p>Kami buatkan aplikasi sesuai kebutuhan Anda, dengan fitur yang Anda tentukan sendiri! Solusi teknologi yang unik dan terkustom hanya untuk Anda.</p>
			          <a href="https://api.whatsapp.com/send?phone=6283834020351&text=Aku%20tertarik%20untuk%20membuat%20aplikasi%20sesuai%20kebutuhanku!" className="thm-btn banner-two__btn">Hubungi Kami <i className="fa fa-angle-double-right" /></a>{/* /.thm-btn */}
			        </div>{/* /.banner-two__content */}
			      </div>{/* /.col-xl-7 */}
			    </div>{/* /.row */}
			  </div>{/* /.container */}
        <FloatingWhatsApp />
			</section>
      

        }
}

export default BannerV2