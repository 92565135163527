import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Service extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return	<section className="service-two" style={{backgroundImage: 'url('+publicUrl+'assets/images/shapes/dotted-patter-1.png)'}}>
		  <div className="container  go-top">
		    <div className="row">
		      <div className="col-lg-5 d-flex">
		        <div className="my-auto">
		          <div className="service-two__block">
		            <div className="block-title-two text-left">
		              <p>Our Services</p>
		              <h3>Fitur Aplikasi <br />
		                Ditentukan oleh <br />
		                Anda</h3>
		            </div>{/* /.block-title-two */}
		            <p>Aplikasi ini beneran bisa kamu atur sesuka hati, loh! Semua fiturnya fleksibel banget, bisa kamu pilih, tambah, atau ubah sesuai kebutuhan kamu. Mau buat kerja, hiburan, atau sekadar buat seru-seruan, tinggal atur sendiri! Pokoknya, kamu bebas bikin aplikasi ini jadi sesuai banget sama gaya kamu. Intinya, semua fitur di sini kamu yang tentuin! </p>
		            <a href='https://api.whatsapp.com/send?phone=6283834020351&text=Aku%20tertarik%20untuk%20membuat%20aplikasi%20sesuai%20kebutuhanku!' className="thm-btn">View All Services <i className="fa fa-angle-double-right" /></a>
		            {/* /.thm-btn */}
		          </div>{/* /.service-two__block */}
		        </div>{/* /.my-auto */}
		      </div>{/* /.col-lg-5 */}
		      <div className="col-lg-7">
		        <div className="service-two__box-wrap">
		          <div className="row">
		            <div className="col-md-6">
		              <div className="service-two__single js-tilt">
		                <div className="service-two__single-image" style={{backgroundImage: 'url(assets/images/backgrounds/service-bg-1-1.jpg)'}} />
		                {/* /.service-two__single-image */}
		                <div className="service-two__single-inner">
		                  <i className="fal fa-hand-holding-usd" />
		                  <h3><a to="/service-details">Tentukan Tujuan Aplikasi</a></h3>
		                  <p>Kamu tentukan tujuan dibuatnya aplikasi yang akan kami buat</p>
		                  <Link to="/service-details" className="service-two__link"><i className="fa fa-long-arrow-alt-right" /></Link>
		                  {/* /.service-two__link */}
		                </div>{/* /.service-two__single-inner */}
		              </div>{/* /.service-two__single */}
		            </div>{/* /.col-md-6 */}
		            <div className="col-md-6">
		              <div className="service-two__single js-tilt">
		                <div className="service-two__single-image" style={{backgroundImage: 'url(assets/images/backgrounds/service-bg-1-1.jpg)'}} />
		                {/* /.service-two__single-image */}
		                <div className="service-two__single-inner">
		                  <i className="fal fa-umbrella-beach" />
		                  <h3><a to="/service-details">Rancang Fitur yang Dibutuhin </a></h3>
		                  <p>Kamu tentuin fitur-fitur apa aja yang kamu pengen biar aplikasinya sesuai sama kebutuhan kamu.</p>
		                  <Link to="/service-details" className="service-two__link"><i className="fa fa-long-arrow-alt-right" /></Link>
		                  {/* /.service-two__link */}
		                </div>{/* /.service-two__single-inner */}
		              </div>{/* /.service-two__single */}
		            </div>{/* /.col-md-6 */}
		            <div className="col-md-6">
		              <div className="service-two__single js-tilt">
		                <div className="service-two__single-image" style={{backgroundImage: 'url(assets/images/backgrounds/service-bg-1-1.jpg)'}} />
		                {/* /.service-two__single-image */}
		                <div className="service-two__single-inner">
		                  <i className="fal fa-paper-plane" />
		                  <h3><a to="/service-details">Uji Coba Bareng</a></h3>
		                  <p>Kita tes aplikasinya bareng, biar kamu bisa kasih feedback langsung kalau ada yang kurang pas.</p>
		                  <a to="/service-details" className="service-two__link"><i className="fa fa-long-arrow-alt-right" /></a>
		                  {/* /.service-two__link */}
		                </div>{/* /.service-two__single-inner */}
		              </div>{/* /.service-two__single */}
		            </div>{/* /.col-md-6 */}
		            <div className="col-md-6">
		              <div className="service-two__single js-tilt">
		                <div className="service-two__single-image" style={{backgroundImage: 'url(assets/images/backgrounds/service-bg-1-1.jpg)'}} />
		                {/* /.service-two__single-image */}
		                <div className="service-two__single-inner">
		                  <i className="fal fa-chart-network" />
		                  <h3><a to="/service-details">Peluncuran & Optimasi</a></h3>
		                  <p>Setelah semua siap, aplikasi diluncurin deh, terus kita siapin perbaikan buat bikin performanya makin mantap!</p>
		                  <Link to="/service-details" className="service-two__link"><i className="fa fa-long-arrow-alt-right" /></Link>
		                  {/* /.service-two__link */}
		                </div>{/* /.service-two__single-inner */}
		              </div>{/* /.service-two__single */}
		            </div>{/* /.col-md-6 */}
		          </div>{/* /.row */}
		        </div>{/* /.service-two__box-wrap */}
		      </div>{/* /.col-lg-7 */}
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>

        }
}

export default Service