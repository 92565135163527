import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Video extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return  <section className="video-one  go-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="video-one__box wow fadeInLeft" data-wow-duration="1500ms">
                  <img src={publicUrl+"assets/images/resources/video-1-1.jpg"} alt={ imagealt } />
                  <a href="https://www.youtube.com/watch?v=Rw1JBhaoHBs" className="video-popup video-one__btn"><i className="fa fa-play" /></a>{/* /.video-popup video-one__btn */}
                </div>{/* /.video-one__box */}
              </div>{/* /.col-lg-6 */}
              <div className="col-lg-6 d-flex">
                <div className="my-auto">
                  <div className="video-one__content">
                    <div className="block-title-two text-left">
                      <p>What we do</p>
                      <h3>Fitur Aplikasi <br /> Anda yang Menentukan.</h3>
                    </div>{/* /.block-title-two */}
                    <p>Pembuatan aplikasi benar-benar dikendalikan oleh anda. Ungkapkan segala kebutuhanku kepada kami, lalu kami yang akan membuatnya khusus untuk anda </p>
                    <ul className="list-unstyled video-one__list">
                      <li>
                        <i className="far fa-check" />
                        Fitur anda yang menentukan
                      </li>
                      <li>
                        <i className="far fa-check" />
                        Pembuatan aplikasi benar-benar dikendalikan oleh anda
                      </li>
                      <li>
                        <i className="far fa-check" />
                        Ungkapkan segala kebutuhan anda kepada kami
                      </li>
                      <li>
                        <i className="far fa-check" />
                        Kami yang akan membuat aplikasi yang benar-benar khusus untuk anda
                      </li>
                    </ul>{/* /.list-unstyled video-one__list */}
                    <a href="https://api.whatsapp.com/send?phone=6283834020351&text=Aku%20tertarik%20untuk%20membuat%20aplikasi%20sesuai%20kebutuhanku!" className="thm-btn">Informasi Lebih Lanjut <i className="fa fa-angle-double-right" /></a>
                    {/* /.thm-btn */}
                  </div>{/* /.video-one__content */}
                </div>{/* /.my-auto */}
              </div>{/* /.col-lg-6 */}
            </div>{/* /.row */}
          </div>{/* /.container */}
        </section>

        }
}

export default Video